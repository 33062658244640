import style from './Cards.module.css'
import classNames from 'classnames/bind'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

export default function Cards () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const router = useRouter()
  const lang = router.locale.split('-')[0]
  const langExtension = lang !== 'en' ? '-' + lang : ''

  return (
    <div className={c('cards')}>
      <section>
        <h3>{t('cards-learnmore-title')}</h3>
        <div>
          <ul>
            <li>
              <Link href={'/sg/investing-in-silver' + langExtension}>
                <a className={c('card', 'silver')}>
                  <span className={c('category')}>{t('cards-learning-category')}</span>
                  <h4>{t('cards-investinsilver')}</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/silverbars.jpg')}&w=384&q=75`} alt={t('cards-investinsilver')} />
                </a>
              </Link>
            </li>
            <li>
              <Link href={'/sg/singapore-guide-to-gold-savings-and-gold-investments' + langExtension}>
                <a className={c('card', 'gold')}>
                  <span className={c('category')}>{t('cards-learning-category')}</span>
                  <h4>{t('cards-singaporeguidetogoldsavings')}</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/goldbars.jpg')}&w=384&q=75`} alt={t('cards-singaporeguidetogoldsavings')} />
                </a>
              </Link>
            </li>
            <li>
              <Link href={'/sg/why-should-i-save-in-gold' + langExtension}>
                <a className={c('card', 'gold')}>
                  <span className={c('category')}>{t('cards-learning-category')}</span>
                  <h4>{t('cards-reasonsforgold')}</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/goldbars.jpg')}&w=384&q=75`} alt={t('cards-reasonsforgold')} />
                </a>
              </Link>
            </li>
            <li>
              <Link href={'/sg/gold-versus-inflation' + langExtension}>
                <a className={c('card', 'gold')}>
                  <span className={c('category')}>{t('cards-learning-category')}</span>
                  <h4>{t('cards-protectagainstinflation')}</h4>
                  <img src={`/_next/image?url=${encodeURIComponent('/images/goldbars.jpg')}&w=384&q=75`} alt={t('cards-protectagainstinflation')} />
                </a>
              </Link>
            </li>
            <li>
              <Link href='/explore'>
                <a className={c('card')}>
                  <span className={c('category')}>{t('cards-learning-category')}</span>
                  <h4>{t('cards-alwaysbacked')}</h4>
                  <img src={`/_next/image?url=${encodeURIComponent(`/images/og_starsavingsaccount${langExtension}.png`)}&w=384&q=75`} alt={t('cards-alwaysbacked')} />
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}
