import style from './Footer.module.css'

import classNames from 'classnames/bind'
import Link from 'next/link'
import Newsletter from '../Newsletter'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export default function Layout () {
  const c = classNames.bind(style)
  const { t } = useTranslation('main')
  const router = useRouter()

  return (
    <>
      <Newsletter />
      <footer>
        <div className={c('language')}>
          <Link href={router.pathname} locale='en-sg'><a>English</a></Link>
          <Link href={router.pathname} locale='zh-sg'><a>中文</a></Link>
          <Link href={router.pathname} locale='de-de'><a>Deutsch</a></Link>
        </div>
        <div className={c('footer')}>
          <div className={c('logo')}>
            <Link href='/sg'><a><img src='/images/logo-icon-gold.svg' loadinbg='lazy' alt={t('footer-sitename')} /></a></Link>
          </div>
          <div className={c('nav')}>
            <nav>
              <Link href='/sg/contact'><a>{t('footer-contactus-link')}</a></Link>
              <a href='https://www.silverbullion.com.sg/TermsOfService/STARGrams' target='_blank' rel='noopener noreferrer'>{t('footer-terms-link')}</a>
              <a href='https://www.silverbullion.com.sg/privacy' target='_blank' rel='noopener noreferrer'>{t('footer-privacy-link')}</a>
            </nav>
            <p>
              © {new Date().getFullYear()} {t('footer-silverbullionrights')}<br />
              PSPM License PS20190001043
            </p>
          </div>
          <div className={c('social')}>
            <ul>
              <li>
                <a href='https://www.facebook.com/silverbullionsg' title='Facebook' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-facebook.svg' loading='lazy' alt={t('footer-follow-facebook')} />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/silverbullionsg/' title='Instagram' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-instagram.svg' loading='lazy' alt={t('footer-follow-instagram')} />
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/c/SilverBullion' title='Youtube' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/icon-youtube.svg' loading='lazy' alt={t('footer-follow-youtube')} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
